import React, { useState } from 'react'
import style from './Collapse.module.scss'
import ArrowTopIcon from '@/assets/icons/icon-arrow-top.svg'
import ArrowBottomIcon from '@/assets/icons/icon-arrow-bottom.svg'

interface ICollapse {
  title: string
  children: React.ReactNode
  isOpen?: boolean
}

function Collapse({ title, children, isOpen = false }: ICollapse) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isOpen)

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className={style.container}>
      <div className={style.container__button}>
        <button onClick={handleToggleCollapse}>
          <div className={style.container__title}>
            <p>
              <span>{title}</span>
            </p>
          </div>
          {isCollapsed ? <ArrowTopIcon /> : <ArrowBottomIcon />}
        </button>
      </div>
      {isCollapsed && <>{children}</>}
    </div>
  )
}

export default Collapse
