import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import style from './CardTag.module.scss'

interface ITag {
  name: string
}

function CardTag({ name }: ITag) {
  const isMobile = useMediaQuery({ minWidth: 1193 })
  const [backgroundStyle, setBackgroundSttyle] = useState<React.CSSProperties>({})

  const tagSize = {
    interview: {
      width: isMobile ? 135 : 88,
      height: isMobile ? 36 : 24,
    },
    news: {
      width: isMobile ? 95 : 64,
      height: isMobile ? 36 : 24,
    },
    insight: {
      width: isMobile ? 112 : 75,
      height: isMobile ? 36 : 24,
    },
    update: {
      width: isMobile ? 112 : 75,
      height: isMobile ? 36 : 24,
    },
  }

  useEffect(() => {
    setBackgroundSttyle({
      backgroundImage: `url("${process.env.NEXT_PUBLIC_CF_COMMON_ASSET_URL}/tags/tag_${name}.png")`,
      ...tagSize[name],
    })
  }, [name])

  return name ? (
    <div style={backgroundStyle} className={style.container}>
      <p>
        <span>{name}</span>
      </p>
    </div>
  ) : null
}

export default CardTag
