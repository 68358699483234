import React, { useEffect, useState } from 'react'
import style from './Document.module.scss'
import useGetCfDocument from '@/features/common/hooks/useGetCfDocument'

interface IDocument {
  url: string
}

export default function Document({ url }: IDocument) {
  const { getUrlDocument } = useGetCfDocument()
  const [document, setDocument] = useState<string>()

  const getDocument = async () => {
    const result = await getUrlDocument(url)
    setDocument(result)
  }

  useEffect(() => {
    getDocument()
  }, [])

  return (
    <div className={style.container}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: document || '' }} />
      </div>
    </div>
  )
}
