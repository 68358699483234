import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'

export default function useHideForSomeDay(key: string) {
  const [openModal, setOpenModal] = useState(true)
  const [lastViewedDate, setLastViewedDate] = useState(localStorage.getItem(key))
  const [hideForOneDay, setHideForOneDay] = useState(true)

  const handleConditionalClose = () => {
    let conditionDays: string | null = null
    const today = dayjs().format('YYYY-MM-DD')

    conditionDays = today

    localStorage.setItem(key, conditionDays)
    setLastViewedDate(conditionDays)
    setHideForOneDay(true)
  }

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD')

    if (_.isNil(lastViewedDate) || dayjs(lastViewedDate).diff(dayjs(today), 'day') < 0) {
      setHideForOneDay(false)
    }
  }, [lastViewedDate])

  const handleClose = () => {
    setOpenModal(false)
  }

  return {
    openModal,
    hideForOneDay,
    handleConditionalClose,
    handleClose,
  }
}
