import { useEffect, useState } from 'react'
import { CONTENTS_DETAIL_PATH, INTERVIEW_DETAIL_PATH } from '@/constants/routes'
import useCustomRouter from '@/features/common/hooks/useCustomRouter'

const useHeaderHandler = () => {
  const { pathname } = useCustomRouter()
  const [isChangeColor, setIsChangeColor] = useState<boolean>(true)
  const [isChangeColorPath, setIsChangeColorPath] = useState<boolean>(true)
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false)

  const handleChangeHeader = () => {
    if (window.scrollY >= 620) {
      setIsChangeColor(false)
    } else {
      setIsChangeColor(true)
    }
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseOut = () => {
    setIsMouseOver(false)
  }

  useEffect(() => {
    if (!isChangeColorPath) return setIsChangeColor(false)

    window.addEventListener('scroll', handleChangeHeader)
  })

  useEffect(() => {
    if (!isChangeColorPath) return setIsChangeColor(false)
  }, [isChangeColorPath])

  useEffect(() => {
    setIsChangeColorPath(pathname.includes(INTERVIEW_DETAIL_PATH) || pathname.includes(CONTENTS_DETAIL_PATH))
  }, [pathname])

  return { isChangeColor, isMouseOver, handleMouseOver, handleMouseOut }
}

export default useHeaderHandler
