const useScrollControl = () => {
  const handleTopScrolling = () => {
    const elem = document.querySelector('html')
    if (!elem) return
    if (typeof elem.scrollTo !== 'undefined') {
      elem.scrollTo(0, 0)
    } else {
      elem.scrollTop = 0
    }
  }

  return { handleTopScrolling }
}

export default useScrollControl
