import classNames from 'classnames'
import style from './Keyword.module.scss'
import { KeywordColorType } from '@/features/common/types/keywordColorType'

interface IKeyword {
  keyword: string
  colorType?: string
}

function Keyword({ keyword, colorType = KeywordColorType.BLACK }: IKeyword) {
  return <p className={classNames(style.container, style[colorType])}>#{keyword}</p>
}

export default Keyword
