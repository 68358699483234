import React, { ButtonHTMLAttributes } from 'react'
import style from './Button.module.scss'
import classNames from 'classnames'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string
  label: string
  styleType?: 'default' | 'default-outlined' | 'white' | 'white-outlined'
}

function Button({ width = 'auto', label, styleType = 'default', ...props }: IButton) {
  return (
    <button {...props} style={{ width }} className={classNames(style.container, style[styleType])}>
      {label}
    </button>
  )
}

export default Button
