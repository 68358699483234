import { useRef, useEffect, useCallback } from 'react'

const useScrollFadeIn = (direction = 'up', duration = 1, delay = 0, threshold = 0.7) => {
  const element = useRef<HTMLInputElement>(null)

  const handleDirection = (name: string) => {
    switch (name) {
      case 'up':
        return 'translate3d(0, 10%, 0)'
      case 'down':
        return 'translate3d(0, -10%, 0)'
      case 'left':
        return 'translate3d(10%, 0, 0)'
      case 'right':
        return 'translate3d(-10%, 0, 0)'
      default:
    }
  }

  const handleScroll = useCallback(
    ([entry]) => {
      const { current } = element
      if (entry.isIntersecting && current) {
        current.style.transitionProperty = 'all'
        current.style.transitionDuration = `${duration}s`
        current.style.transitionTimingFunction = 'cubic-bezier(0.33, 1, 0.68, 1)'
        current.style.transitionDelay = `${delay}s`
        current.style.opacity = '1'
        current.style.transform = 'translate3d(0, 0, 0)'
      }
    },
    [delay, duration],
  )

  useEffect(() => {
    let observer: IntersectionObserver
    const { current } = element

    if (current) {
      observer = new IntersectionObserver(handleScroll, { threshold })
      observer.observe(current)
    }

    return () => observer && observer.disconnect()
  }, [handleScroll])

  return {
    ref: element,
    style: {
      opacity: 0,
      transform: handleDirection(direction),
    },
  }
}

export default useScrollFadeIn
