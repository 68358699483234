import classNames from 'classnames'
import React, { ButtonHTMLAttributes } from 'react'
import { useDeepLink } from '@/features/common/hooks/useDeepLink'
import style from './DownloadButton.module.scss'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: 'default' | 'line'
}

function AppDownloadButton({ styleType = 'default', className, ...restProps }: IButton) {
  const { launchAppOrStore } = useDeepLink()

  return (
    <button
      className={classNames(style.button_container, style[styleType], className)}
      {...restProps}
      onClick={launchAppOrStore}
    >
      더 운반 앱 다운로드
    </button>
  )
}

export default AppDownloadButton
