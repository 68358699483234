import { useContext, useState } from 'react'
import classNames from 'classnames'
import style from './ResponsiveMenu.module.scss'
import Link from 'next/link'
import { CONTACT_PATH, DRIVER_EVENT, HOME_PATH, OWNER_EVENT, SERVICE_PATH, CAREER_PATH } from '@/constants/routes'
import { useDeepLink } from '@/features/common/hooks/useDeepLink'
import { EventContext } from '@/providers/EventProvider'

function ResponsiveMenu() {
  const { isShowPage } = useContext(EventContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { launchAppOrStore } = useDeepLink()

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <nav className={style.container}>
      <div
        onClick={handleOpen}
        className={classNames(style.menu_container, {
          [style.is_open]: isOpen,
        })}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      {isOpen && (
        <div className={style.menu__nav}>
          <nav className={style.header__nav}>
            <Link href={HOME_PATH}>홈</Link>

            {isShowPage && (
              <div>
                <p>이벤트</p>
                <Link href={OWNER_EVENT}>화주 이벤트</Link>
                <Link href={DRIVER_EVENT}>차주 이벤트</Link>
              </div>
            )}
            <Link href={SERVICE_PATH}>서비스 소개</Link>
            <Link href={CONTACT_PATH}>운송문의</Link>
            <Link href='#' onClick={launchAppOrStore}>
              회원가입
            </Link>
            {/* 검색결과노출을 위해 미노출 링크 등록 */}
            <Link href={CAREER_PATH} style={{ display: 'none' }}>
              채용
            </Link>
          </nav>
        </div>
      )}
    </nav>
  )
}

export default ResponsiveMenu
