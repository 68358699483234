import Image from 'next/image'
import style from './QuickLink.module.scss'
import Quick from '@/assets/images/quick.png'
import { useFadeInAnimation } from '@/features/common/hooks'
import { useDeepLink } from '@/features/common/hooks/useDeepLink'

function QuickLink() {
  const { animationFadeInUp } = useFadeInAnimation()
  const { launchAppOrStore } = useDeepLink({ fallback: 'QUICK' })

  return (
    <div onClick={launchAppOrStore} {...animationFadeInUp[0]} className={style.container}>
      <Image src={Quick} alt='더 운반 퀵 링크 이미지' />
    </div>
  )
}

export default QuickLink
