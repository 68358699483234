export const HOME_PATH = '/'
export const SERVICE_PATH = '/service'
export const CONTACT_PATH = '/contact'
export const CAREER_PATH = '/career'

// event
export const OWNER_EVENT = '/event/owner'
export const DRIVER_EVENT = '/event/driver'

// article
export const CONTENTS_PATH = '/contents'
export const CONTENTS_DETAIL_PATH = '/contents/[id]'

// interview
export const INTERVIEW_PATH = '/interview'
export const INTERVIEW_DETAIL_PATH = '/interview/[id]'

// Driver Policy

// TODO  gyemin.lee 기존 OWNER.TRANSPORT 주소가 driver로 잘못 되어 있는데, owner 로 수정
export const POLICY = {
  DRIVER: {
    LOCATION: '/policy/driver/location',
    TRANSPORT: '/policy/driver/transport',
    COLLECTION_PERSONAL_INFO: '/policy/driver/collection-personal-info',
    PROVIDING_LOCATION_INFO_THIRD_PARTIES: '/policy/driver/providing-location-info-third-parties',
    PROVIDING_PERSONAL_INFO_THIRD_PARTIES: '/policy/driver/providing-personal-info-third-parties',
  },
  OWNER: {
    TRANSPORT: '/policy/owner/transport',
  },
  ADVERTISE: '/policy/advertise',
  SERVICE: '/policy/service',
  PERSONAL: {
    PRIVACY: '/policy/privacy',
    PRIVACY20230309: '/policy/privacy20230309',
    PRIVACY20230523: '/policy/privacy20230523',
    PRIVACY20230614: '/policy/privacy20230614',
  },
}
